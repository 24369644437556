import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  ConceptMedia,
} from '../../../components/_index';
import '../../../assets/_sass/page/about.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout checkjump="privacy" checken="en">
      <SEO  checken="en" title={frontmatter?.title} description={frontmatter?.description}  />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'Terms and Conditions',
            sub: 'Terms and Conditions',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/privacy/kv.png',
              },
              imgSp: {
                src: '/assets/images/privacy/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
        checken="en"
          data={{
            parent: [],
            current: {
              label: 'Terms and Conditions',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CBtnList
            exClass="p_aboutBtnList p_aboutBtnList--privacy u_mt0"
            data={[
              {
                label: 'Facility Terms of Use',
                link: {
                  href: '/en/privacy/facility_terms_of_use/',
                },
              },
              {
                label: 'Site Terms of Use',
                link: {
                  href: '/en/privacy/site/',
                },
              },
              {
                label: 'Privacy Policy',
                link: {
                  href: 'https://www.royalparkhotels.co.jp/en/privacy/',
                  blank: true,
                },
              },
            ]}
          />
          <br/>

          <CBtnList
            exClass="p_aboutBtnList p_aboutBtnList--privacy u_mt0"
            data={[
              {
                label: 'Social Media Policy',
                link: {
                  href: 'https://www.royalparkhotels.co.jp/en/social/',
                  blank: true,
                },
              },
              {
                label: (
                  <>
                    Act on Specified Commercial Transactions
                    
                  </>
                ),
                link: {
                  href: 'https://www.royalparkhotels.co.jp/en/transaction/',
                  blank: true,
                },
              },
            ]}
          />
         
        </LWrap>
      </section>
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
